import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="spell-list"></a><h2>Spell List</h2>
    <a id="bard-spells"></a><h3>BARD SPELLS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a id="zero-level-bard-spells"></a><a id="bard-cantrip"></a><h5>0-LEVEL BARD SPELLS (CANTRIPS)</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dancing-lights">Dancing Lights</a>:</td>
          <td> Creates torches or other lights.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#daze">Daze</a>:</td>
          <td> Humanoid creature of 4 HD or less loses next action.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-magic">Detect Magic</a>:</td>
          <td> Detects spells and magic items within 60 ft.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#flare">Flare</a>:</td>
          <td> Dazzles one creature (&ndash;1 on attack rolls).</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#ghost-sound">Ghost Sound</a>:</td>
          <td> Figment sounds.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#know-direction">Know Direction</a>:</td>
          <td> You discern north.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#light">Light</a>:</td>
          <td> Object shines like a torch.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#lullaby">Lullaby</a>:</td>
          <td> Makes subject drowsy; &ndash;5 on <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="skillsAll.html#Spot">Spot</a>
and <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="skillsAll.html#Listen">Listen</a>
checks, &ndash;2
on Will saves against sleep.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mage-hand">Mage Hand</a>:</td>
          <td> 5-pound telekinesis.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mending">Mending</a>:</td>
          <td> Makes minor repairs on an object.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#message">Message</a>:</td>
          <td> Whispered conversation at distance.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#open/close">Open/Close</a>:</td>
          <td> Opens or closes small or light things.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#prestidigitation">Prestidigitation</a>:</td>
          <td> Performs minor tricks.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#read-magic">Read Magic</a>:</td>
          <td> Read scrolls and spellbooks.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#resistance">Resistance</a>:</td>
          <td> Subject gains +1 on saving throws.</td>
        </tr>
        <tr>
          <td className="last-row"><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-instrument">Summon Instrument</a>:</td>
          <td className="last-row"> Summons one instrument of the
caster&rsquo;s choice.</td>
        </tr>
      </tbody>
    </table>
    <a id="first-level-bard-spells"></a><h5>1ST-LEVEL BARD SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#alarm">Alarm</a>:</td>
          <td> Wards an area for 2 hours/level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animate-rope">Animate Rope</a>:</td>
          <td> Makes a rope move at your command.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cause-fear">Cause Fear</a>:</td>
          <td> One creature of 5 HD or less flees for 1d4 rounds.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#charm-person">Charm Person</a>:</td>
          <td> Makes one person your friend.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#comprehend-languages">Comprehend
Languages</a>:</td>
          <td> You understand all spoken and written languages.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cure-light-wounds">Cure Light
Wounds</a>:</td>
          <td> Cures 1d8 damage +1/level (max +5).</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-secret-doors">Detect
Secret Doors</a>:</td>
          <td> Reveals hidden doors within 60 ft.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#disguise-self">Disguise Self</a>:</td>
          <td> Changes your appearance.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#erase">Erase</a>:</td>
          <td> Mundane or magical writing vanishes.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#expeditious-retreat">Expeditious
Retreat</a>:</td>
          <td> Your speed increases by 30 ft.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#feather-fall">Feather Fall</a>:</td>
          <td> Objects or creatures fall slowly.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#grease">Grease</a>:</td>
          <td> Makes 10-ft. square or one object slippery.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#hideous-laughter">Hideous
Laughter</a>:</td>
          <td> Subject loses actions for 1 round/ level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#hypnotism">Hypnotism</a>:</td>
          <td> Fascinates 2d4 HD of creatures.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#identify">Identify</a><sup> M</sup>:</td>
          <td> Determines properties of magic item.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#lesser-confusion">Lesser
Confusion</a>:</td>
          <td> One creature is <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#confused">confused</a> for 1 round.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-mouth">Magic Mouth</a><sup>
M</sup>:</td>
          <td> Speaks once when triggered.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-aura">Magic Aura</a>:</td>
          <td> Alters object&rsquo;s magic aura.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#obscure-object">Obscure Object</a>:</td>
          <td> Masks object against scrying.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#remove-fear">Remove Fear</a>:</td>
          <td> Suppresses fear or gives +4 on saves against fear for
one subject + one per four levels.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#silent-image">Silent Image</a>:</td>
          <td> Creates minor illusion of your design.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sleep">Sleep</a>:</td>
          <td> Puts 4 HD of creatures into magical slumber.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-i">Summon Monster I</a>:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#undetectable-alignment">Undetectable
Alignment</a>:</td>
          <td> Conceals alignment for 24 hours.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#unseen-servant">Unseen Servant</a>:</td>
          <td> Invisible force obeys your commands.</td>
        </tr>
        <tr>
          <td className="last-row"><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#ventriloquism">Ventriloquism</a>:</td>
          <td className="last-row"> Throws voice for 1 min./level.</td>
        </tr>
      </tbody>
    </table>
    <a id="second-level-bard-spells"></a><h5>2ND-LEVEL BARD SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#alter-self">Alter Self</a>:</td>
          <td> Assume form of a similar creature.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animal-messenger">Animal
Messenger</a>:</td>
          <td> Sends a Tiny animal to a specific place.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animal-trance">Animal Trance</a>:</td>
          <td> Fascinates 2d6 HD of animals.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#blindness/deafness">Blindness/Deafness</a>:</td>
          <td> Makes subject <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#blinded">blind</a> or <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#deafened">deaf</a>.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#blur">Blur</a>:</td>
          <td> Attacks miss subject 20% of the time.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#calm-emotions">Calm Emotions</a>:</td>
          <td> Calms creatures, negating emotion effects.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cats-grace">Cat&rsquo;s Grace</a>:</td>
          <td> Subject gains +4 to Dex for 1 min./level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cure-moderate-wounds">Cure
Moderate Wounds</a>:</td>
          <td> Cures 2d8 damage +1/level (max +10).</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#darkness">Darkness</a>:</td>
          <td> 20-ft. radius of supernatural shadow.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#daze-monster">Daze Monster</a>:</td>
          <td> Living creature of 6 HD or less loses next action.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#delay-poison">Delay Poison</a>:</td>
          <td> Stops poison from harming subject for 1 hour/ level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-thoughts">Detect
Thoughts</a>:</td>
          <td> Allows &ldquo;listening&rdquo; to surface thoughts.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#eagles-splendor">Eagle&rsquo;s
Splendor</a>:</td>
          <td> Subject gains +4 to Cha for 1 min./level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#enthrall">Enthrall</a>:</td>
          <td> Captivates all within 100 ft. + 10 ft./level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#foxs-cunning">Fox&rsquo;s
Cunning</a>:</td>
          <td> Subject gains +4 to Int for 1 min./level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#glitterdust">Glitterdust</a>:</td>
          <td> Blinds creatures, outlines invisible creatures.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#heroism">Heroism</a>:</td>
          <td> Gives +2 on attack rolls, saves, skill checks.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#hold-person">Hold Person</a>:</td>
          <td> Paralyzes one humanoid for 1 round/level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#hypnotic-pattern">Hypnotic
Pattern</a>:</td>
          <td> Fascinates (2d4 + level) HD of creatures.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#invisibility">Invisibility</a>:</td>
          <td> Subject is <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#invisible">invisible</a> for 1 min./level or
until it
attacks.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#locate-object">Locate Object</a>:</td>
          <td> Senses direction toward object (specific or type).</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#minor-image">Minor Image</a>:</td>
          <td> As silent image, plus some sound.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mirror-image">Mirror Image</a>:</td>
          <td> Creates decoy duplicates of you (1d4 +1 per three
levels, max 8).</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#misdirection">Misdirection</a>:</td>
          <td> Misleads divinations for one creature or object.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#pyrotechnics">Pyrotechnics</a>:</td>
          <td> Turns fire into <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#blinded">blinding</a> light or choking smoke.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#rage">Rage</a>:</td>
          <td> Gives +2 to Str and Con, +1 on Will saves, &ndash;2 to
AC.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#scare">Scare</a>:</td>
          <td> Panics creatures of less than 6 HD.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shatter">Shatter</a>:</td>
          <td> Sonic vibration damages objects or crystalline
creatures.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#silence">Silence</a>:</td>
          <td> Negates sound in 20-ft. radius.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sound-burst">Sound Burst</a>:</td>
          <td> Deals 1d8 sonic damage to subjects; may stun them.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#suggestion">Suggestion</a>:</td>
          <td> Compels subject to follow stated course of action.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-ii">Summon Monster
II</a>:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-swarm">Summon Swarm</a>:</td>
          <td> Summons swarm of bats, rats, or spiders.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#tongues">Tongues</a>:</td>
          <td> Speak any language.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#whispering-wind">Whispering
Wind</a>:</td>
          <td> Sends a short message 1 mile/level.</td>
        </tr>
      </tbody>
    </table>
    <a id="third-level-bard-spells"></a><h5>3RD-LEVEL BARD SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#blink">Blink</a>:</td>
          <td> You randomly vanish and reappear for 1 round/level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#charm-monster">Charm Monster</a>:</td>
          <td> Makes monster believe it is your ally.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#clairaudience/clairvoyance">Clairaudience/Clairvoyance</a>:</td>
          <td> Hear or see at a distance for 1 min./level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#confusion">Confusion</a>:</td>
          <td> Subjects behave oddly for 1 round/level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#crushing-despair">Crushing Despair</a>:</td>
          <td> Subjects take &ndash;2 on attack rolls, damage rolls,
saves,
and checks.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cure-serious-wounds">Cure Serious
Wounds</a>:</td>
          <td> Cures 3d8 damage +1/level (max +15).</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#daylight">Daylight</a>:</td>
          <td> 60-ft. radius of bright light.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#deep-slumber">Deep Slumber</a>:</td>
          <td> Puts 10 HD of creatures to sleep.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dispel-magic">Dispel Magic</a>:</td>
          <td> Cancels magical spells and effects.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#displacement">Displacement</a>:</td>
          <td> Attacks miss subject 50%.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#fear">Fear</a>:</td>
          <td> Subjects within cone flee for 1 round/level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#gaseous-form">Gaseous Form</a>:</td>
          <td> Subject becomes insubstantial and can fly slowly.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#lesser-geas">Geas, Lesser</a>:</td>
          <td> Commands subject of 7 HD or less.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#glibness">Glibness</a>:</td>
          <td> You gain +30 bonus on <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="skillsAll.html#bluff">Bluff</a>
checks, and your lies can escape magical discernment.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#good-hope">Good Hope</a>:</td>
          <td> Subjects gain +2 on attack rolls, damage rolls, saves,
and checks.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#haste">Haste</a>:</td>
          <td> One creature/level moves faster, +1 on attack rolls,
AC, and Reflex saves.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#illusory-script">Illusory
Script</a><sup> M</sup>:</td>
          <td> Only intended reader can decipher.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#invisibility-sphere">Invisibility
Sphere</a>:</td>
          <td>Makes everyone within 10 ft. <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#invisible">invisible</a>.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#major-image">Major Image</a>:</td>
          <td> As silent image, plus sound, smell and thermal effects.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#phantom-steed">Phantom Steed</a>:</td>
          <td> Magic horse appears for 1 hour/level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#remove-curse">Remove Curse</a>:</td>
          <td> Frees object or person from curse.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#scrying">Scrying</a><sup> F</sup>:</td>
          <td> Spies on subject from a distance.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sculpt-sound">Sculpt Sound</a>:</td>
          <td> Creates new sounds or changes existing ones.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#secret-page">Secret Page</a>:</td>
          <td> Changes one page to hide its real content.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#see-invisibility">See Invisibility</a>:</td>
          <td> Reveals <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#invisible">invisible</a> creatures
or objects.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sepia-snake-sigil">Sepia Snake
Sigil</a><sup> M</sup>:</td>
          <td> Creates text symbol that immobilizes reader.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#slow">Slow</a>:</td>
          <td> One subject/level takes only one action/round, �1 to AC, reflex saves, and attack rolls.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#speak-with-animals">Speak with
Animals</a>:</td>
          <td> You can communicate with animals.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-iii">Summon
Monster III</a>:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr>
          <td className="last-row"><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#tiny-hut">Tiny
Hut</a>:</td>
          <td className="last-row"> Creates shelter for ten creatures.</td>
        </tr>
      </tbody>
    </table>
    <a id="fourth-level-bard-spells"></a><h5>4TH-LEVEL BARD SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#break-enchantment">Break
Enchantment</a>:</td>
          <td> Frees subjects from enchantments, alterations, curses,
and petrification.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cure-critical-wounds">Cure
Critical Wounds</a>:</td>
          <td> Cures 4d8 damage +1/level (max +20).</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-scrying">Detect Scrying</a>:</td>
          <td> Alerts you of magical eavesdropping.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dimension-door">Dimension Door</a>:</td>
          <td> Teleports you short distance.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dominate-person">Dominate
Person</a>:</td>
          <td> Controls humanoid telepathically.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#freedom-of-movement">Freedom
of Movement</a>:</td>
          <td> Subject moves normally despite impediments.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#hallucinatory-terrain">Hallucinatory
Terrain</a>:</td>
          <td> Makes one type of terrain appear like another (field
into forest, or the like).</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#hold-monster">Hold Monster</a>:</td>
          <td> As hold person, but any creature.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#greater-invisibility">Invisibility,
Greater</a>:</td>
          <td> As invisibility, but subject can attack and stay <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#invisible">invisible</a>.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#legend-lore">Legend Lore</a><sup>
M F</sup>:</td>
          <td> Lets you learn tales about a person, place, or thing.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#locate-creature">Locate
Creature</a>:</td>
          <td> Indicates direction to familiar creature.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#modify-memory">Modify Memory</a>:</td>
          <td> Changes 5 minutes of subject&rsquo;s memories.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#neutralize-poison">Neutralize
Poison</a>:</td>
          <td> Immunizes subject against poison, detoxifies venom in
or on subject.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#rainbow-pattern">Rainbow
Pattern</a>:</td>
          <td> Lights fascinate 24 HD of creatures.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#repel-vermin">Repel Vermin</a>:</td>
          <td> Insects, spiders, and other vermin stay 10 ft. away.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#secure-shelter">Secure Shelter</a>:</td>
          <td> Creates sturdy cottage.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shadow-conjuration">Shadow
Conjuration</a>:</td>
          <td> Mimics conjuring below 4th level, but only 20% real.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shout">Shout</a>:</td>
          <td> Deafens all within cone and deals 5d6 sonic damage.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#speak-with-plants">Speak with
Plants</a>:</td>
          <td> You can talk to normal plants and plant creatures.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-iv">Summon Monster
IV</a>:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#zone-of-silence">Zone of
Silence</a>:</td>
          <td> Keeps eavesdroppers from overhearing conversations.</td>
        </tr>
      </tbody>
    </table>
    <a id="fifth-level-bard-spells"></a><h5>5TH-LEVEL BARD SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#mass-cure-light-wounds">Cure
Light Wounds, Mass</a>:</td>
          <td> Cures 1d8 damage +1/level for many creatures.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#greater-dispel-magic">Dispel
Magic, Greater</a>:</td>
          <td> As dispel magic, but +20 on check.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dream">Dream</a>:</td>
          <td> Sends message to anyone sleeping.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#false-vision">False Vision</a><sup>
M</sup>:</td>
          <td> Fools scrying with an illusion.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#greater-heroism">Heroism,
Greater</a>:</td>
          <td> Gives +4 bonus on attack rolls, saves, skill checks;
immunity to fear; temporary hp.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mind-fog">Mind Fog</a>:</td>
          <td> Subjects in fog get &ndash;10 to Wis and Will checks.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mirage-arcana">Mirage Arcana</a>:</td>
          <td> As hallucinatory terrain, plus structures.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mislead">Mislead</a>:</td>
          <td> Turns you <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#invisible">invisible</a> and creates illusory
double.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#nightmare">Nightmare</a>:</td>
          <td> Sends vision dealing 1d10 damage, fatigue.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#persistent-image">Persistent
Image</a>:</td>
          <td> As major image, but no concentration required.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#seeming">Seeming</a>:</td>
          <td> Changes appearance of one person per two levels.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shadow-evocation">Shadow Evocation</a>:</td>
          <td> Mimics evocation of lower than 5th level, but only 20%
real.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shadow-walk">Shadow Walk</a>:</td>
          <td> Step into shadow to travel rapidly.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#song-of-discord">Song of Discord</a>:</td>
          <td> Forces targets to attack each other.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#mass-suggestion">Suggestion, Mass</a>:</td>
          <td> As suggestion, plus one subject/level.</td>
        </tr>
        <tr>
          <td className="last-row"><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-v">Summon
Monster V</a>:</td>
          <td className="last-row"> Calls extraplanar creature to fight
for you.</td>
        </tr>
      </tbody>
    </table>
    <a id="sixth-level-bard-spells"></a><h5>6TH-LEVEL BARD SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#analyze-dweomer">Analyze
Dweomer</a><sup> F</sup>:</td>
          <td> Reveals magical aspects of subject.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animate-objects">Animate
Objects</a>:</td>
          <td> Objects attack your foes.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#mass-cats-grace">Cat&rsquo;s
Grace, Mass</a>:</td>
          <td> As cat&rsquo;s grace, affects one subject/level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#mass-charm-monster">Charm
Monster, Mass</a>:</td>
          <td> As charm monster, but all within 30 ft.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#mass-cure-moderate-wounds">Cure
Moderate Wounds, Mass</a>:</td>
          <td> Cures 2d8 damage +1/level for many creatures.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#mass-eagles-splendor">Eagle&rsquo;s
Splendor, Mass</a>:</td>
          <td> As eagle&rsquo;s splendor, affects one subject/level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#eyebite">Eyebite</a>:</td>
          <td> Target becomes <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#panicked">panicked</a>, <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#sickened">sickened</a>, and comatose.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#find-the-path">Find the Path</a>:</td>
          <td> Shows most direct way to a location.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#mass-foxs-cunning">Fox&rsquo;s
Cunning, Mass</a>:</td>
          <td> As fox&rsquo;s cunning, affects one subject/level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#geas/quest">Geas/Quest</a>:</td>
          <td> As lesser geas, plus it affects any creature.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#heroes-feast">Heroes&rsquo;
Feast</a>:</td>
          <td> Food for one creature/level cures and grants combat
bonuses.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#irresistible-dance">Irresistible
Dance</a>:</td>
          <td> Forces subject to dance.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#permanent-image">Permanent
Image</a>:</td>
          <td> Includes sight, sound, and smell.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#programmed-image">Programmed
Image</a><sup> M</sup>:</td>
          <td> As major image, plus triggered by event.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#project-image">Project Image</a>:</td>
          <td> Illusory double can talk and cast spells.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#greater-scrying">Scrying, Greater</a>:</td>
          <td> As scrying, but faster and longer.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#greater-shout">Shout, Greater</a>:</td>
          <td> Devastating yell deals 10d6 sonic damage; stuns
creatures, damages objects.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-vi">Summon Monster
VI</a>:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sympathetic-vibration">Sympathetic
Vibration</a>:</td>
          <td> Deals 2d10 damage/round to freestanding structure.</td>
        </tr>
        <tr>
          <td className="last-row"><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#veil">Veil</a>:</td>
          <td className="last-row"> Changes appearance of group of
creatures.</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      